<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Member <span style="color: red">*</span></label
                >
                <multiselect
                  @search-change="fetchMemberList"
                  id="exp"
                  v-model="form.member_id"
                  :options="memberList"
                  :multiple="false"
                  track-by="member_id"
                  label="fnameAndLname"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.member_id.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.member_id.required"
                  class="invalid-feedback"
                >
                  Member is required.
                </div>
              </b-form-group>
              <b-form-group class="col-6">
                <label for="multi_event" style="font-weight: 500"
                  >Product <span style="color: red">*</span></label
                >
                <multiselect
                  id="item"
                  :options="getProduct"
                  v-model="form.product"
                  :multiple="false"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  @input="productChange"
                  :class="{
                    'is-invalid': submitted && $v.form.product.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.product.required"
                  class="invalid-feedback"
                >
                  product is required.
                </div>
              </b-form-group>
              <b-form-group class="col-6">
                <div class="d-flex justify-content-between align-items-center">
                  <label for="multi_event" style="font-weight: 500"
                    >Product Item <span style="color: red">*</span></label
                  >
                </div>
                <multiselect
                  id="item"
                  :options="getProductItem"
                  v-model="form.product_item"
                  :multiple="false"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.product_item.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.product_item.required"
                  class="invalid-feedback"
                >
                  Product Item is required.
                </div>
              </b-form-group>
              <b-form-group class="col-6">
                <label for="multi_event" style="font-weight: 500" class=""
                  >Coupon
                </label>
                <multiselect
                  id="multi_event"
                  :options="getCoupons"
                  v-model="form.coupon_id"
                  :multiple="false"
                  track-by="id"
                  label="code"
                  placeholder="Type here to search"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  Add Manual Payment
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import paymentOrder from "../../../../mixins/ModuleJs/payment-order";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, paymentOrder],
  data() {
    return {
      submitted: false,
      title: "Add Manual Payment",
      items: [
        {
          text: "Back",
          href: "/order",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      member_id: { required },
      product: { required },
      product_item: { required },
    },
  },
};
</script>
